<template>
  <div>
    <section class="goal">
      <h2>表示・非表示</h2>
      <div class="wrap">
        <button @click="isShowTrue">表示する</button>
        <button @click="isShowFalse">非表示にする</button>
        <p v-if="isShow" class="loading">ローディング中...</p>
      </div>
    </section>
    <hr class="border" />
    <p class="border_txt">これ以下は模写不要</p>
    <section class="question">
      <h2>問題</h2>
      <div class="wrap">
        <p class="question_txt">以上のアプリケーションを作成しなさい</p>
      </div>
    </section>
    <section class="answer">
      <h2>回答</h2>
      <div v-if="showAnswer">
        <button class="answer_btn" @click="showAnswer = false">
          回答を非表示
        </button>
        <div class="wrap">
          <pre>
            <code v-pre>
&lt;template&gt;
  &lt;div&gt;
    &lt;button @click="isShowTrue"&gt;表示する&lt;/button&gt;
    &lt;button @click="isShowFalse"&gt;非表示にする&lt;/button&gt;
    &lt;p v-if="isShow" class="loading"&gt;ローディング中...&lt;/p&gt;
  &lt;/div&gt;
&lt;/template&gt;

&lt;script&gt;
export default {
  data() {
    return {
      isShow: false,
    };
  },
  methods: {
    isShowTrue() {
      this.isShow = true;
    },
    isShowFalse() {
      this.isShow = false;
    },
  },
};
&lt;/script&gt;
&lt;style lang="scss" scoped&gt;
.loading {
  color: blue;
}
&lt;/style&gt;
            </code>
          </pre>
        </div>
      </div>
      <button v-else class="answer_btn" @click="showAnswer = true">
        回答を表示
      </button>
    </section>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showAnswer: false,
      isShow: false,
    };
  },
  methods: {
    isShowTrue() {
      this.isShow = true;
    },
    isShowFalse() {
      this.isShow = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.loading {
  color: blue;
}
</style>
import { render, staticRenderFns } from "./Page2.vue?vue&type=template&id=e5fdbe52&scoped=true"
import script from "./Page2.vue?vue&type=script&lang=js"
export * from "./Page2.vue?vue&type=script&lang=js"
import style0 from "./Page2.vue?vue&type=style&index=0&id=e5fdbe52&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e5fdbe52",
  null
  
)

export default component.exports